import moment from 'moment'

export const dateMixin = {
    methods: {
        // method helper
        frontEndDateFormat: function (date) {
            if (date) {
                return moment(date, 'YYYY-MM-DD').format('D MMMM YYYY')
            } else {
                return null;
            }
        },
        backEndDateFormat: function (date) {
            if (date) {
                return moment(date, 'DD MMMM YYYY').format('YYYY-MM-DD')
                // return new Date(date)
            } else {
                return null;
            }
        },
        localString: function (date) {
            if (date) {
                const bulan = [
                    "Januari",
                    "Februari",
                    "Maret",
                    "April",
                    "Mei",
                    "Juni",
                    "Juli",
                    "Agustus",
                    "September",
                    "Oktober",
                    "November",
                    "Desember"
                ]
                const tgl = new Date(date)
                let tanggal = tgl.getDate()
                let month = bulan[tgl.getMonth()]
                let year = tgl.getFullYear()
                let result = tanggal + ' ' + month + ' ' + year
                return result
            } else {
                return null
            }
        }
    }
}

export default dateMixin