import axios from 'axios'
import hapusStorage from '../hapusLocalStore'

export const sptDetailApi = {
    mixins: [hapusStorage],
    data () {
        return {
            sptDetailMixin: [],
        }
    },
    methods: {
        dataSptDetail (id) {
            axios.get("/api/transaksi/spt-detail/" + id).then(response => {
                const items = response.data.data
                this.sptDetailMixin = items
                this.form.fill(items)
                this.form.ret_jenis_1_id= items.ret_tarif.ret_jenis_3.ret_jenis_2.ret_jenis_1_id;
                this.form.ret_jenis_2_id= items.ret_tarif.ret_jenis_3.ret_jenis_2_id;
                this.form.ret_jenis_3_id= items.ret_tarif.ret_jenis_3_id;
                this.form.ret_tarif_id  = items.ret_tarif_id;
                this.form.tarif_manual  = items.tarif_manual ? true : false;
                this.form.tarif         = this.formatENtoID(items.tarif);
                this.form.qty           = this.formatENtoID(items.qty);
                this.form.nilai_terutang= this.formatENtoID(items.nilai_terutang);
                // ...
                this.$store.dispatch("StepRetribusi/dataRetJenis2", this.form.ret_jenis_1_id)
                this.$store.dispatch("StepRetribusi/dataRetJenis3", this.form.ret_jenis_2_id)
                this.$store.dispatch("StepRetribusi/dataRetTarif", this.form.ret_jenis_3_id)
                console.log(items)
            }).catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    // hapus storage mixins
                    this.clearAll()
                }
            })
        },
        dataSpt (id) {
            axios.get("/api/transaksi/spt/" + id).then(response => {
                const items = response.data.data
                if (items.npwrd.no_pokok) {
                    this.editMode = false
                    this.checkNpwrd(items.npwrd.no_pokok, "pokok");
                }
            }).catch(error =>{
                console.log(error)
                if (error.response.status === 401) {
                    // hapus storage mixins
                    this.clearAll()
                }
            })
        },

        /**
         * FORMAT NUMBER & CURRENCY
         * --------------------------------------------------
         */
        formatENtoID(value){
            if(value){
                let valueID = value ? value : 0;
                // if (valueID.indexOf('.') > -1){
                //     alert("hello found inside your_string");
                // }
                valueID     = valueID.toString().replace('.', ','); // mengganti desimal . menjadi ,
                valueID     = valueID.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                return valueID;
            }else{
                // return 0;
            }
        },
        formatIDtoEN(value){
            if(value){
                let valueEN = value ? value.toString().replaceAll('.','') : 0;
                valueEN     = valueEN.toString().replace(',', '.'); // mengganti desimal , menjadi .
                return valueEN;
            }else{
                // return 0;
            }
        },
        /**
         * GET TOTAL
         * --------------------------------------------------
         */
        getTotal() {
            // tarif ...
            let tarif = Number(this.form.tarif ? this.form.tarif.toString().replaceAll('.','') : 0);
            // qty ...
            let qty   = this.form.qty ? this.form.qty.toString().replaceAll('.','') : 0;
            qty       = qty.toString().replaceAll(',','.'); // mengganti desimal , menjadi .
            qty       = parseFloat(qty).toFixed(2); // membatasi hanya 2 digit desimal
            // nilai_terutang ...
            let nilai_terutang = tarif * qty;
            this.form.nilai_terutang = Math.round(nilai_terutang)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
    }
}

export default sptDetailApi